/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import PageWrapper from '../PageWrapper/PageWrapper';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import Section from '../../molecules/Section/Section';
import CaptionedIcon from '../../molecules/CaptionedIcon/CaptionedIcon';
import sanityImage from '../../../utils/sanityImage';
import { Heading2, Heading4 } from '../../atoms/Headings/Headings';

import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

const IconGridSection = ({
  backgroundImage,
  heading,
  icons,
  iconSize,
  sectionStyles,
}) => {
  // if sanity passes undefined we need to set it to a default of 5
  const iconsPerRow = 5;
  const gridItemWidth = 100 / iconsPerRow;

  const visibilityCss = css`
    visibility: hidden;

    @media (max-width: ${pageDimensions.smallDesktopWidth}) {
      display: none;
    }
  `;

  /**
   * Render each icon.
   *
   * @return {*}
   */
  const renderIcons = () =>
    icons.map(icon => {
      if (icon.visibility === 'hidden') {
        // eslint-disable-next-line
        return <div className="captioned-icon" css={visibilityCss} />
      }
      return <CaptionedIcon key={icon._key} {...icon} iconSize={iconSize} />;
    });

  const iconTotal = Object.keys(icons).length;

  let leftOvers = iconTotal % iconsPerRow;

  if (leftOvers < iconsPerRow / 2) leftOvers = iconsPerRow - leftOvers;

  if (leftOvers > 0) {
    for (let i = leftOvers; i > -1; i -= 1) {
      icons.push({
        _key: `leftovers-${i}`,
        asset: {},
        icon: {},
        visibility: 'hidden',
      });
    }
  }

  const updatedIcons = renderIcons();

  let bgImageCss = {};
  if (backgroundImage) {
    bgImageCss = {
      background: `transparent url(${sanityImage(backgroundImage)
        .auto('format')
        .width(1300)
        .fit('max')
        .url()}) 0 0 no-repeat;`,
      backgroundSize: 'cover',
    };
  }

  const headingCss = css`
    width: 100%;
    max-width: ${uc('530px')};
    margin: ${uc('80px auto 20px')};
    font-size: ${fontSizes.thirty};
    text-align: center;
  `;

  const gridCss = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: ${uc('150px')};
    padding: ${uc('0 150px')};

    .captioned-icon {
      width: ${gridItemWidth}%;
      margin: 0;
      padding: ${uc('30px 0')};
      text-align: center;

      img,
      svg {
        display: block;
        width: ${uc(`${iconSize}px`)};
        max-height: ${uc(`${iconSize}px`)};
        margin: ${uc('0 auto 10px')};
      }

      ${Heading4} {
        width: 55%;
        margin: 0 auto;
        color: ${colors.darkGray.one};
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.fourteen};
        font-family: ${fontFamilies.roboto};
        text-transform: uppercase;
        word-wrap: initial;

        @media (${breakpoints.mobile}) {
          width: 100%;
        }
      }

      @media (max-width: ${pageDimensions.smallDesktopWidth}) {
        width: ${iconsPerRow > 4
          ? '25%'
          : // show 4 if iconsPerRow is more than 4
            `${gridItemWidth}%`};
      }

      @media (${breakpoints.tablet}) {
        width: ${iconsPerRow > 3
          ? '33%'
          : // show 3 if default is to show more than 3
            `${gridItemWidth}%`};
      }

      @media (${breakpoints.mobile}) {
        width: 50%;
      }
    }

    @media (${breakpoints.tablet}) {
      padding: ${uc('0 20px')};
    }
  `;

  /**
   * Render icon grid section
   */
  return (
    <Section
      sectionStyles={sectionStyles}
      className="icon-grid-section"
      css={bgImageCss}
    >
      <PageWrapper>
        {heading && <Heading2 css={headingCss}>{heading}</Heading2>}
        <DeprecatedGrid className={`icons cols-${iconsPerRow} `} css={gridCss}>
          {updatedIcons}
        </DeprecatedGrid>
      </PageWrapper>
    </Section>
  );
};

IconGridSection.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  heading: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        asset: PropTypes.shape({}).isRequired,
      }),
    })
  ).isRequired,
  iconSize: PropTypes.number,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

IconGridSection.defaultProps = {
  backgroundImage: false,
  heading: false,
  iconSize: 40,
  sectionStyles: {},
};

export default IconGridSection;
