/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import { Heading4 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';

/**
 * Displays an icon with an option caption below it.
 *
 * @param icon
 * @param caption
 * @return {*}
 */
const CaptionedIcon = ({ caption, description, icon, iconSize }) => (
  <div className="captioned-icon">
    {icon && (
      <Image
        src={sanityImage(icon)
          .auto('format')
          .width(iconSize)
          .fit('max')
          .url()}
        alt={`${caption} icon`}
      />
    )}
    {/* eslint-disable-next-line react/no-danger */}
    {caption && <Heading4>{caption}</Heading4>}
    {description && <Text>{description}</Text>}
  </div>
);

CaptionedIcon.defaultProps = {
  caption: '',
  description: '',
  icon: null,
  iconSize: 40,
};

CaptionedIcon.propTypes = {
  caption: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.shape({}),
  iconSize: PropTypes.number,
};

export default CaptionedIcon;
